import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";
import PhoneIcon from "@material-ui/icons/Phone";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import {Helmet} from "react-helmet";

class WorkSection extends React.Component {
    state = {
        privacyChecked: false,
        recapture: false,
        fields: {},
        errors: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            privacyChecked: false,
            fields: {},
            errors: {}
        };
    }


    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = true;
        }

        //Message
        if(!fields["message"]){
            formIsValid = false;
            errors["message"] = "Cannot be empty";
        }

        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Bitte geben Sie Ihre EMailadresse an. ";
        }

        if(typeof fields["email"] !== "undefined"){
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Bitte prüfen Sie Ihre Emailadresse.";
            }
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    handlePrivacy = e => {
        this.setState({ privacyChecked: e.target.checked });
    }

    handleChange(e){

        let errors = this.state.errors;
        errors[e.target.name] = false;
        this.setState({errors});

        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({fields});
    }

    validateRecaptcha() {
        var response = window.grecaptcha.enterprise.getResponse();
        if (response.length === 0) {

            return false;
        } else {

            return true;
        }
    }

    contactSubmit(e){
        e.preventDefault();

        if (this.handleValidation()){
            if (this.validateRecaptcha()) {
                e.target.submit();
            }
        }
    }

    render() {
    const { classes } = this.props;

    return (
        <>
            <Helmet>
                <script src="https://www.google.com/recaptcha/enterprise.js"></script>
            </Helmet>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
              <h2 className={classes.title}>Fragen Sie uns</h2>
              <form action="https://getform.io/f/6bf985c5-01b4-4dfd-983a-33e09a362d2f" onSubmit= {this.contactSubmit.bind(this)} method="POST">
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                        labelText="Ihr Name"
                        id="name"
                        error={this.state.errors["name"]}
                        name="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                            onChange: (event) => this.handleChange(event),
                        }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                        labelText="Ihre E-Mailadresse"
                        error={this.state.errors["email"]}
                        id="email"
                        name="email"
                        onChange={this.handleChange.bind(this, "email")}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                            onChange: (event) => this.handleChange(event),
                        }}
                    />
                  </GridItem>
                  <CustomInput
                      labelText="Ihre Nachricht"
                      id="message"
                      name="message"
                      error={this.state.errors["message"]}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea
                      }}
                      inputProps={{
                          onChange: (event) => this.handleChange(event),
                        multiline: true,
                        rows: 5
                      }}
                  />
                  <GridItem xs={12} sm={12} md={12}>
                      <label>
                          <input type="checkbox" onChange={(e) => this.handlePrivacy(e)} name="privacy" /> Mit dem Absenden stimme ich den <a href="/SchreinersApotheken/Datenschutz/" target="_blank">Datenschutzbestimmungen</a> zu.
                      </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <label>
                          <div className="g-recaptcha" data-sitekey="6LfJkpcaAAAAAPvqxZlitBnNuGzg9dKe6pQgZ4-D"></div>
                      </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <Button color="success" type="submit" disabled={!this.state.privacyChecked} >senden</Button>
                      </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>

        </div>
            </>
    );
  }
}

export default withStyles(workStyle)(WorkSection);
